import React, { useEffect, useRef, useState } from 'react';
import { useAudioDeviceManagerContext } from '../../../Contexts/AudioDeviceContext';
import { AnimatePresence, motion } from 'framer-motion';
import './AudioDeviceManager.css';
import { images } from '../../../Constants';
import { BrowserView } from 'react-device-detect'

const AudioDeviceManagerComponent = () => {
    const {
        availableAudioDevices,
        currentAudioDevice,
        initializedAudio,
        audioError,
        selectAudioDevice,
        cleanupAudio,
    } = useAudioDeviceManagerContext();

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleChange = async(deviceId) => {
        setIsOpen(false);
        await selectAudioDevice(deviceId);
    }

    if(audioError) {
        return <div style={{color: 'red'}}>Error: {audioError.message}</div>
    }

    if(!initializedAudio) {
        return <div>Loading audio devices...</div>
    }

    return (
      <BrowserView>
      <div className='audio-manager__container' ref={dropdownRef}>
      {availableAudioDevices.length > 0 ? (
        <React.Fragment>
          <label style={{color: 'white'}} htmlFor="audio-device-list">Twój mikrofon:</label>
          <div className='audio-manager__current-device'
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <p>{currentAudioDevice ? currentAudioDevice.label : "-- Select a device --"}</p>
            <img src={images.arrowDown}/>
          </div>

          <AnimatePresence>
            {isOpen && (
              <motion.ul
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "fit-content" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.25 }}
              >
                {availableAudioDevices.map((device) => (
                  <motion.li
                    key={device.deviceId}
                    style={{
                      background: currentAudioDevice?.deviceId === device.deviceId ? "#e0f7fa" : "white",
                    }}
                    whileHover={{ backgroundColor: "#f1f1f1" }}
                    onClick={() => handleChange(device.deviceId)}
                  >
                    <label>
                      <input
                        type="radio"
                        name="audio-device"
                        value={device.deviceId}
                        checked={currentAudioDevice?.deviceId === device.deviceId}
                        readOnly
                      />
                      {device.label}
                    </label>
                  </motion.li>
                ))}
              </motion.ul>
            )}
          </AnimatePresence>

          {/* {currentAudioDevice && (
            <p>
              <strong>Selected Device:</strong> {currentAudioDevice.label}
            </p>
          )} */}
        </React.Fragment>
      ) : (
        <div>No available recording devices.</div>
      )}
    </div>
    </BrowserView>
    )
}
    
export default AudioDeviceManagerComponent;