import React, { createContext, useContext, useState } from "react";

const ScreenContext = createContext();

export const useScreenContext = () => useContext(ScreenContext);

export const ScreenProvider = ({ children }) => {
    const [currentScreen, setCurrentScreen] = useState("start");
    const [errorMessage, setErrorMessage] = useState("");
    const [endMessage, setEndMessage] = useState("");
    const [showErrorForUser, setShowErrorForUser] = useState(false);

    const setError = (show, errorMessage) => {
        setCurrentScreen("error");
        setErrorMessage(errorMessage);
        setShowErrorForUser(show);
    }

    return (
        <ScreenContext.Provider value={{ currentScreen, setCurrentScreen, errorMessage, setError, showErrorForUser, endMessage, setEndMessage }}>
            {children}
        </ScreenContext.Provider>
    )
}