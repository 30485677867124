/**
 * Voice Activity Detection (VAD) configuration
 */
export const VAD_CONFIG = {
    // VAD settings
    minSpeechDuration: 500,     // Increased from 300ms to reduce false starts
    maxSpeechDuration: 10000,   // Increased to allow longer sentences
    positiveSpeechThreshold: 0.90,  // Increased for more confident speech detection
    negativeSpeechThreshold: 0.85,  // Increased to prevent premature cutoffs
    preSpeechPadFrames: 10,     // Increased to capture more context before speech
    redemptionFrames: 8,        // Increased to be more lenient with pauses
    silenceDuration: 10000,      // Duration of silence before stopping (new)
    mode: 'quality',            // 'quality' mode for more accurate but slightly slower detection (new)
    frameDuration: 30           // Frame duration in ms (new)
}

/**
 * Voice processing states
 */
export const VOICE_STATES = {
    IDLE: 'idle',           // Not listening
    LISTENING: 'listening', // Listening but no speech detected
    SPEAKING: 'speaking',   // Speech detected
    PROCESSING: 'processing', // Processing detected speech
    ERROR: 'error'         // Error state
}

/**
 * Audio feedback sound files
 */
export const AUDIO_FEEDBACK = {
    START_LISTENING: '/assets/sounds/active.wav',
    STOP_LISTENING: '/assets/sounds/inactive.wav',
    ERROR: '/assets/sounds/error.mp3'
}

/**
 * Voice processing configuration
 */
export const VOICE_PROCESSING = {
    sampleRate: 16000,      // Sample rate for audio processing (required by Whisper)
    maxAudioLength: 30,     // Maximum audio length in seconds
    silenceThreshold: -50,  // dB threshold for silence detection
    vadEnabled: true       // Enable/disable VAD
} 