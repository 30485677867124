import React, { useState, useEffect, useRef } from 'react';
import axios from '../../axios';

const DisableApp = () => {
    const [contentLoaded, setContentLoaded] = useState(false);
    const [currentAppState, setCurrentAppState] = useState(null);
    const [responseMessage, setResponseMessage] = useState('');
    const passRef = useRef();

    useEffect(() => {
        axios.get('/appStatus').then((response) => {
            setCurrentAppState(response.data.appStatus);
            setContentLoaded(true);
        })
    }, [])

    const submitChange = async() => {
        const password = passRef.current.value;
        passRef.current.value = '';

        await axios.post('/changeApplicationStatus', {password: password}).then((response) => {
            setResponseMessage(response.data.message);
            axios.get('/appStatus').then((response) => {
                setCurrentAppState(response.data.appStatus);
            })
            setTimeout(() => {
                setResponseMessage('');
            }, 5000)
        })
    }

    return (
        <div>
            {contentLoaded && (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 24, width: '100%', backgroundColor: '#232D7D', color: 'white'}}>
                    <div style={{display: 'flex', gap: 16, alignItems: 'center'}}>
                        <div style={{width: 32, height: 32, borderRadius: 16, background: currentAppState ? '#00ff00' : '#ff0000'}} />
                        <h1>App is currently {currentAppState ? 'live' : 'offline'}.</h1>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 8}}>
                        <label>Password</label>
                        <input ref={passRef} type='text' placeholder='Password' />
                        <button onClick={submitChange}>{currentAppState ? 'TURN OFF THE APP' : 'TURN ON THE APP'}</button>
                    </div>
                    <span>{responseMessage}</span>
                </div>
            )}
        </div>
    )
}

export default DisableApp;