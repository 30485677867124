/**
 * Avatar and Heygen API configuration
 */
export const avatarConfig = {
    baseURL: 'https://api.heygen.com',
    avatar: {
        quality: 'High',
        avatarName: 'Ann_Therapist_public',
        voice: {
            voiceId: '6eafa43fdc16437b8f5abe512cc2b3cf',
            rate: 1,
            emotion: 'friendly' // Voice emotion:['Excited','Friendly','Serious','Soothing','Broadcaster']
        },
        disableIdleTimeout: true
    },
    session: {
        activeSessionCheck: {
            status: 'active',
            avatarName: 'Ann_Therapist_public'
        }
    }
}; 