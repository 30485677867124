import React, { useState, useEffect, useCallback, createContext, useContext, useRef } from 'react';
import { audioDeviceManager } from '../Services/AudioDeviceManager';

const AudioDeviceContext = createContext();

export function useAudioDeviceManagerContext() {
    return useContext(AudioDeviceContext);
}

export const AudioDeviceProvider = ({ children }) => {
    const [availableAudioDevices, setAvailableAudioDevices] = useState([]);
    const [currentAudioDevice, setCurrentAudioDevice] = useState(null);
    const [initializedAudio, setInitializedAudio] = useState(false);
    const [audioError, setAudioError] = useState(null);

    const hasInit = useRef(false);
    const success = useRef(null);

    useEffect(() => {
        let mounted = true;

        const init = async() => {
            try {   
                if(!hasInit.current) {
                    hasInit.current = true
                    success.current = await audioDeviceManager.initialize();
                }
                if(!mounted) return;
                if(success) {
                    setAvailableAudioDevices(audioDeviceManager.getAvailableDevices());
                    setCurrentAudioDevice(audioDeviceManager.getCurrentDevice());
                    setInitializedAudio(true);
                } else {
                    setAudioError('Failed to initialize audio device');
                }
            } catch(err) {
                setAudioError(err);
            }
        }

        init();
        

        const onDeviceSelected = (device) => {
            setCurrentAudioDevice(device);
        }

        const onDevicesUpdated = (devices) => {
            setAvailableAudioDevices(devices);
        }

        audioDeviceManager.on('deviceSelected', onDeviceSelected);
        audioDeviceManager.on('devicesUpdated', onDevicesUpdated);

        return () => {
            mounted = false;
            audioDeviceManager.off('deviceSelected', onDeviceSelected);
            audioDeviceManager.off('devicesUpdated', onDevicesUpdated);
        }

    }, []);

    const selectAudioDevice = useCallback(async(deviceId) => {
        try {
            await audioDeviceManager.selectDevice(deviceId);
        } catch(err) {
            setAudioError(err);
        }
    }, []);

    const cleanupAudio = useCallback(() => {
        audioDeviceManager.cleanup();
        setAvailableAudioDevices([]);
        setCurrentAudioDevice(null);
        setInitializedAudio(false);
    }, []);

    const value = {
        availableAudioDevices,
        currentAudioDevice,
        initializedAudio,
        audioError,
        selectAudioDevice,
        cleanupAudio,
    };

    return (
        <AudioDeviceContext.Provider value={value}>
            {children}
        </AudioDeviceContext.Provider>
    )
}