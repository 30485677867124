import React, { useState, useEffect } from 'react';
import { useScreenContext } from './../../Contexts/ScreenContext';
import { useDataContext } from '../../Contexts/DataContext';
import LoaderModule from '../Modules/LoaderModule';
import './End.css'
import { AnimatePresence, motion } from 'framer-motion';
import { images } from '../../Constants';

const End = () => {
    const [contentLoaded, setContentLoaded] = useState(false);

    const { setCurrentScreen, endMessage } = useScreenContext();
    const { surveyData, setSurveyData } = useDataContext();


    useEffect(() => {
        setContentLoaded(true);
    }, []);

    return (
        <div className='end__container'>
            <AnimatePresence>
                {contentLoaded ? (
                    <motion.div 
                        className='end' 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.4 } }}
                    >
                        <div className='end__top'>
                            <img src={images.logotype}/>
                        </div>
                        <div className='end__content'>
                            <h1>{surveyData.name}</h1>
                            <h3>Dziękujemy za ankietę!</h3>
                            <p>{endMessage}</p>
                        </div>
                        <img src={images.background} />
                    </motion.div>
                ) : (
                    <LoaderModule type={'default'} />
                )}
            </AnimatePresence>
        </div>
    )
}

export default End;