import React, { useEffect, forwardRef, useRef, useImperativeHandle, useState } from 'react';
import ModalModule from '../ModalModule';
import { audioDeviceManager } from '../../../Services/AudioDeviceManager';
import './GrantPermissions.css';
import { images } from '../../../Constants';

const GrantPermissionsComponent = forwardRef(({nextPage}, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        open() {
            setIsOpen(true);
        },
    }))

    const handleClose = () => {
        setIsOpen(false);
    }

    const handleRequestAccess = async() => {
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            await audioDeviceManager.updateAvailableDevices();
            nextPage(2);
            handleClose();

        } catch(err) {
            console.log(err);
        }
    }

    return (
        <ModalModule closeable={false} onClose={handleClose} isOpen={isOpen}>
            <div className='grant-permissions__container'>
                <div className='grant-permissions__text'>
                    <img src={images.microphone}/>
                    <h3>W celu korzystania z aplikacji zezwól na użycie twojego mikrofonu.</h3>
                </div>
                <button onClick={handleRequestAccess}>Zezwalaj</button>
            </div>
        </ModalModule>
    )
})
    
export default GrantPermissionsComponent;