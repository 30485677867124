import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';

export const CountdownTimerModule = forwardRef(({ initialDelay, questionNumberLeft, onComplete, onWarning }, ref) => {
    const [phase, setPhase] = useState('idle');
    const [count, setCount] = useState(0);
    const timePerQuestion = 30; // seconds

    const intervalRef = useRef(null);
    const questionNumberLeftRef = useRef(questionNumberLeft);

    useEffect(() => {
        questionNumberLeftRef.current = questionNumberLeft;
    }, [questionNumberLeft]);

    const startTimer = () => {
        clearInterval(intervalRef.current);
        setPhase('hidden');
        setCount(initialDelay * 1000);
    }

    const stopTimer = () => {
        clearInterval(intervalRef.current);
        setPhase('idle');
        setCount(0);
    }

    useImperativeHandle(ref, () => ({
        startTimer,
        stopTimer
    }));

    useEffect(() => {
        if(phase === 'idle') return;

        intervalRef.current = setInterval(() => {
            setCount(prevCount => {
                if(prevCount <= 1000) {
                    clearInterval(intervalRef.current);
                    intervalRef.current = null;
                    
                    if(phase === "hidden") {
                        setPhase('visible');
                        setTimeout(() => {
                            if(onWarning) onWarning();
                        }, 0)
                        return (questionNumberLeftRef.current + 1) * timePerQuestion * 1000;

                    } else if(phase === "visible") {
                        setPhase('idle');
                        setTimeout(() => {
                            if(onComplete) onComplete();
                        }, 0)
                        return 0;
                    }
                }

                return prevCount - 1000;
            })
        }, 1000);

        return () => clearInterval(intervalRef.current);
    }, [phase, initialDelay, questionNumberLeft, onComplete]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60000);
        const secs = seconds % 60000 / 1000;
        return `${minutes}:${secs < 10 ? `0` : ''}${secs}`;
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 6, position: 'absolute', left: '50%', transform: 'translateX(-50%)'}}>
            <h2 style={{fontWeight: 700, lineHeight: '24px', fontSize: '1rem', padding: 0, margin: 0, textShadow: '0px 0px 2px black'}}>Ankieter</h2>
            {phase === 'hidden' && (
                <div style={{fontWeight: 700, fontSize: '0.8rem', lineHeight: '19.2px', padding: '2px 8px', transition: 'all 0.2s', borderRadius: 16, backgroundColor: count > 60000 ? 'rgba(0,0,0,0.4)' : 'rgba(228,49,38,1)'}}>{formatTime(count)}</div>
            )}
            {phase === 'visible' && (
                <div style={{fontWeight: 700, fontSize: '0.8rem', lineHeight: '19.2px', padding: '2px 8px', transition: 'all 0.2s', borderRadius: 16, backgroundColor: count > 60000 ? 'rgba(0,0,0,0.4)' : 'rgba(228,49,38,1)'}}>{formatTime(count)}</div>
            )}
            {phase === 'idle' && (
                <div style={{fontWeight: 700, fontSize: '0.8rem', lineHeight: '19.2px', padding: '2px 8px', transition: 'all 0.2s', borderRadius: 16, backgroundColor: count > 60000 ? 'rgba(0,0,0,0.4)' : 'rgba(228,49,38,1)'}}>{formatTime(0)}</div>
            )}
        </div> 
    )
})