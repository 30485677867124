import logotype from './../Assets/logotype.svg';
import logo from './../Assets/logotype.svg';
import logoWide from './../Assets/logotype.svg';
import background from './../Assets/background.svg';
import microphoneOn from './../Assets/mic_on.json';
import microphoneOff from './../Assets/mic_off.svg';
import xMark from './../Assets/xmark.svg';
import microphone from './../Assets/microphone.svg';
import arrowDown from './../Assets/arrow-down.svg';
import arrowLeft from './../Assets/arrow-left.svg';
import tutorial1 from './../Assets/tutorial1.png';
import tutorial2 from './../Assets/tutorial2.png';
import tutorial3 from './../Assets/tutorial3.png';
import tutorial4 from './../Assets/tutorial4.png';

export default {
    logotype,
    logo,
    background,
    microphoneOn,
    microphoneOff,
    xMark,
    microphone,
    arrowDown,
    arrowLeft,
    logoWide,
    tutorial1,
    tutorial2,
    tutorial3,
    tutorial4,
}