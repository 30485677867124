import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import { images } from '../../Constants';

const ModalModule = (props) => {
    const wrapperRef = useRef(null);

    const closeModal = () => {
        if(props.onClose) {
            props.onClose();
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if(wrapperRef.current && !wrapperRef.current.contains(event.target) && props.closeable) {
                closeModal();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [wrapperRef])

    const cssStyling = {
        modalContainer: {
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            background: 'rgba(0,0,0,0.8)',
        },

        modal: {
            background: 'white',
            margin: 24,
            color: 'black',
            borderRadius: 24,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#232D7D',
            gap: 0,
            maxWidth: 600
        },

        closeContainer: {
            width: 'calc(100% - 48px)',
            display: 'flex',
            justifyContent: 'end',
            borderBottom: '1px solid white',
            padding: '16px 24px',
        },

        childrenContainer: {
            padding: 24
        },

        closeButton: {
            cursor: 'pointer',
            background: 'transparent',
            outline: 'none',
            border: 'none'
        },

        closeImg: {
            width: 24,
            filter: 'invert(100%)',
        }
    }

    return (
        <AnimatePresence>
            {props.isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.25 }}
                    style={cssStyling.modalContainer}
                >
                    <motion.div 
                        ref={wrapperRef}
                        initial={{ scale: 0.25, y: 100 }}
                        animate={{ scale: 1, y: 0 }}
                        exit={{ scale: 0.25, y: 100 }}
                        transition={{ duration: 0.25 }}
                        style={cssStyling.modal}
                    >
                        {props.closeable && <div style={cssStyling.closeContainer}>
                            <button style={cssStyling.closeButton} onClick={closeModal}>
                                <img style={cssStyling.closeImg} src={images.xMark}/>
                            </button>
                        </div>}
                        <div style={cssStyling.childrenContainer}>
                            {props.children}
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
        
    )
}
    
export default ModalModule;