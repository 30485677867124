import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import BarLoader from "react-spinners/BarLoader";
import { images } from '../../Constants';

const  LoaderModule = ({type}) => {
    const [currentType, setCurrentType] = useState(null);
    const [indexType, setIndexType] = useState(0);
    const [typeLoaded, setTypeLoaded] = useState(false);

    const timePerText = 5 // In seconds
    const textArray = {
        default: [
            'Ładowanie aplikacji',
            'Przygotowywanie zasobów',
            'Łączenie z serwerem',
        ],
        avatar: [
            'Witaj! Już za chwilę Twój wirtualny ankieter pojawi się na ekranie.',
            'Jeszcze sekundka, pani Ewa zaraz będzie gotowa do rozmowy!',
            'Jeszcze chwilka. Trwa przygotowywanie wirtualnego ankietera.',
            'Łączymy Cię z naszym ankieterem. Zaraz zaczynamy!',
            'Jeszcze tylko kilka sekund - nasz ankieter zaraz będzie gotowy!'
        ]
    }

    useEffect(() => {
        switch(type) {
            case 'avatar': 
                setCurrentType(textArray.avatar);
                break;

            default:
                setCurrentType(textArray.default);
                break;
        }

        setTypeLoaded(true);
    }, [type]);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndexType((prevIndex) => (prevIndex + 1) % currentType.length);
        }, timePerText * 1000)

        return () => clearInterval(interval);
    }, [currentType])

    const cssStyling = {
        loader: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },

        paragraph: {
            width: '100%',
            textAlign: 'center',
            marginInline: 24,
            marginTop: 96,
            maxWidth: 600,
            height: 64,
            position: 'absolute',
            zIndex: 0
        }
    }

    return (
        <React.Fragment>
            {typeLoaded && (
                <div style={cssStyling.loader}>
                    <BarLoader height={8} width={256} color='white' speedMultiplier={0.75} />
                    {currentType.map((item, index) => (
                        <AnimatePresence key={index}>
                        {index == indexType && (
                            <motion.p 
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
                                exit={{ opacity: 0, y: -20, transition: { duration: 0.5 } }}
                                style={cssStyling.paragraph}
                            >
                                {item}
                            </motion.p>
                        )}
                        </AnimatePresence>
                    )
                    )}
                    
                </div>
            )}
            
        </React.Fragment>
        
    )
}
    
export default LoaderModule;