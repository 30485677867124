import React, { createContext, use, useContext, useState } from "react";

const DataContext = createContext();

export const useDataContext = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
    const [surveyData, setSurveyData] = useState([]);
    const [questionsData, setQuestionsData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [promptData, setPromptData] = useState([]);
    const [systemPromptData, setSystemPromptData] = useState("");
    const [externalInfo, setExternalInfo] = useState("");
    const [savedAIPhrases, setSavedAIPhrases] = useState([]);
    const offlineAppPhrases = [
        'Nasz wirtualny ankieter właśnie poszedł na kawę – wróci za moment, obiecujemy!',
        'Nasz wirtualny ankieter stał się bardzo popularny i właśnie udziela wywiadu. Zapraszamy za chwilę.',
        'Nasz wirtualny ankieter jest strasznie zapracowany. Zapraszamy za chwilę!',
        'Nasz wirtualny ankieter jest chwilowo niedostępny – zapraszamy za kilka minut.',
        'Nasz wirtualny ankieter jest teraz offline, ale już szykuje się do kolejnej misji. Wracamy za moment!',
        'Nasz ankieter ma teraz krótką przerwę na posiłek. Ale wraca już za momencik!',
        'Nasz ankieter właśnie karmi wirtualnego kota, ale już za chwilę wraca do działania!'
    ]

    return (
        <DataContext.Provider value={{ surveyData, setSurveyData, questionsData, setQuestionsData, userData, setUserData, systemPromptData, setSystemPromptData, promptData, setPromptData, externalInfo, setExternalInfo, savedAIPhrases, setSavedAIPhrases, offlineAppPhrases }}>
            {children}
        </DataContext.Provider>
    )
}