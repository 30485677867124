import React, { useState, useEffect } from 'react';
import { useScreenContext } from './../../Contexts/ScreenContext';
import LoaderModule from '../Modules/LoaderModule';
import './Error.css';
import { images } from '../../Constants';

const ErrorComponent = () => {
    const [contentLoaded, setContentLoaded] = useState(false);

    const { setCurrentScreen, errorMessage, showErrorForUser } = useScreenContext();

    useEffect(() => {
        setContentLoaded(true);
        if(!showErrorForUser) console.log("ERROR: ", errorMessage);
    }, []);

    return (
        <div className='error__container'>
            {contentLoaded ? (
                <div className='error'>
                    <img src={images.logo} />
                    <div className='error__text'>
                        <h1>Coś poszło nie tak...</h1>
                        {showErrorForUser ? (
                            <p>{errorMessage}</p>
                        ) : (
                            <p>Bardzo przepraszamy za utrudnienia. Spróbuj ponownie poprzez odświeżenie strony. Jeśli problem będzie się powtarzał, skontaktuj się z nami.</p>
                        )}
                    </div>
                </div> 
            ) : (
                <LoaderModule type={'default'} />
            )}
            
        </div>
    )
}

export default ErrorComponent;