import React, { useEffect, useCallback, forwardRef, useImperativeHandle, useState } from 'react';
import { useVoiceInput } from '../../Contexts/VoiceInputContext';
import { VOICE_STATES } from '../../Config/voice.config';
import Lottie from 'lottie-react';
import { images } from '../../Constants';

export const VoiceRecorder = forwardRef(({ isParentInitialized, setInitialized, transcriptionComplete, firstWarningDueToInactivity, stopSurveyDueToInactivity }, ref) => {
    const {
        isRecording,
        isInitialized,
        serviceState,
        error,
        transcription,
        isProcessing,
        startRecording,
        stopRecording,
        initializeVoiceInput,
        deinitializeVoiceInput,
    } = useVoiceInput();

    const firstTimeoutTreshold = 15000;
    const secondTimeoutTreshold = 30000;

    const [activeMic, setActiveMic] = useState(false);

    const handleInitializationState = (isInit) => {
      setInitialized(isInit);
    }

    useEffect(() => {
        handleInitializationState(isInitialized);
    }, [isInitialized])

  useEffect(() => {
      if (transcription && !isProcessing) {
          transcriptionComplete(transcription);
      }
  }, [transcription, isProcessing]);

  useEffect(() => {
    let silenceTimer1;
    let silenceTimer2;

    if(serviceState === VOICE_STATES.LISTENING) {

      silenceTimer1 = setTimeout(() => {
        firstWarningDueToInactivity("Hej, jesteś tam?");
      }, firstTimeoutTreshold);

      silenceTimer2 = setTimeout(() => {
        stopSurveyDueToInactivity();
      }, secondTimeoutTreshold);
    }

    return () => {
      if(silenceTimer1) {
        clearTimeout(silenceTimer1);
      }
      if(silenceTimer2) {
        clearTimeout(silenceTimer2);
      }
    }
  }, [serviceState])

    const handleToggle = async() => {
        isRecording ? await stopRecording() : await startRecording();
    }

    useImperativeHandle(ref, () => ({
      async activateRecording() {
        setActiveMic(true);
        await startRecording();
      },

      async deactivateRecording() {
        setActiveMic(false);
        await stopRecording();
      },

      async initVoiceComponent() {
        await initializeVoiceInput();
      },

      async deinitVoiceComponent() {
        await deinitializeVoiceInput();
      }
    }))

    const getStatus = () => {
        if (serviceState === VOICE_STATES.SPEAKING ) return 'Speak...';
        if (isProcessing) return 'Processing...';
        return isRecording ? 'Recording...' : 'Press to record';
    };

    return (
      <React.Fragment>
        {isParentInitialized && (
          <div className="voice-recorder-container" style={{
            height: 'clamp(64px, 15vw, 128px)',
             width: 'clamp(64px, 15vw, 128px)', 
             display: 'flex', 
             justifyContent: 'flex-end', 
             transition: '0.2s', 
             marginRight: 0, 
             scale: serviceState === VOICE_STATES.LISTENING || serviceState === VOICE_STATES.SPEAKING ? 1.25 : 1,
             position: 'relative',
          }}>
            {activeMic ? (
              <Lottie animationData={images.microphoneOn} loop={true} autoplay={true} style={{width: '100%', height: '100%'}} />
            ) : (
              <img src={images.microphoneOff} />
            )}
          </div>
        )}
      </React.Fragment> 
    )
})