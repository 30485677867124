import React, { useState, useEffect } from 'react';
import LoaderModule from '../Modules/LoaderModule';
import './NoPage.css';
import { images } from '../../Constants';

const NoPageComponent = () => {
    const [contentLoaded, setContentLoaded] = useState(false);

    useEffect(() => {
        setContentLoaded(true);
    }, []);

    return (
        <div className='nopage__container'>
            {contentLoaded ? (
                <div className='nopage'>
                    <img src={images.logo} />
                    <div className='nopage__text'>
                        <h1>Strona nie istnieje.</h1>
                        <p>Podany URL nie istnieje.</p>
                    </div>
                </div> 
            ) : (
                <LoaderModule type={'default'} />
            )}
            
        </div>
    )
}

export default NoPageComponent;