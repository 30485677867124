import { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
import { voiceInputService } from '../Services/VoiceInputService';
import { VOICE_STATES } from '../Config/voice.config';
import axios from './../axios';

const VoiceInputContext = createContext();

export const useVoiceInput = () => {
    return useContext(VoiceInputContext);
}

export const VoiceInputProvider = ({ children }) => {
    const [state, setState] = useState({
        serviceState: VOICE_STATES.IDLE,
        error: null,
        transcription: '',
        isInitialized: false,
        isProcessing: false,
        isAudioActive: false,
    });

    const hasInit = useRef(false);

    const handlers = {
        stateChange: (newState, { isAudioActive }) => {
            console.log(newState);
            setState(prev => ({
            ...prev,
            serviceState: newState,
            isAudioActive
        }))
    },

        speechStart: () => setState(prev => ({
            ...prev,
            transcription: '',
            error: null,
        })),

        speechEnd: async(audioData) => {
            try {
                setState(prev => ({ ...prev, isProcessing: true }));
                const result = await voiceInputService.transcribeAudio(audioData);
                console.log(result);
                setState(prev => ({ ...prev, transcription: result }));
                

            } catch(err) {
                console.log(err);
                setState(prev => ({ ...prev, err }));
            } finally {
                setState(prev => ({ ...prev, isProcessing: false }));
            }
        },

        error: (error) => setState(prev => ({
            ...prev,
            error,
            isProcessing: false
        })),

        audioChannelChange: (isActive) => setState(prev => ({
            ...prev,
            isAudioActive: isActive
        })),
    };

    const initializeVoiceInput = () => {
        const init = async() => {
            try {                                
                await voiceInputService.initialize();
                setState(prev => ({ ...prev, isInitialized: true }));
            } catch(err) {
                setState(prev => ({ ...prev, err }))
            }
        };

        init();

        voiceInputService.on('stateChange', handlers.stateChange);
        voiceInputService.on('speechStart', handlers.speechStart);
        voiceInputService.on('speechEnd', handlers.speechEnd);
        voiceInputService.on('error', handlers.error);
        voiceInputService.on('audioChannelChange', handlers.audioChannelChange);
    }

    const deinitializeVoiceInput = () => {
        voiceInputService.off('stateChange', handlers.stateChange);
        voiceInputService.off('speechStart', handlers.speechStart);
        voiceInputService.off('speechEnd', handlers.speechEnd);
        voiceInputService.off('error', handlers.error);
        voiceInputService.off('audioChannelChange', handlers.audioChannelChange);
        voiceInputService.cleanup();
        setState(prev => ({ ...prev, isInitialized: false }));
    }

    const value = {
        ...state,
        isRecording: [VOICE_STATES.LISTENING, VOICE_STATES.SPEAKING].includes(state.serviceState),
        startRecording: () => voiceInputService.start(),
        stopRecording: () => voiceInputService.stop(),
        initializeVoiceInput,
        deinitializeVoiceInput,
    };

    return (
        <VoiceInputContext.Provider value={value}>
            {children}
        </VoiceInputContext.Provider>
    )
}