import { useEffect, useRef, useCallback, useState } from 'react';
import StreamingAvatar, { StreamingEvents, AvatarQuality } from '@heygen/streaming-avatar';
import axios from './../axios';

export const useHeygenAvatar = (config) => {
    const avatarVideoRef = useRef(null);
  const streamingClient = useRef(null);
  const callbacksRef = useRef({
    onStartTalking: null,
    onStopTalking: null,
  });

  const sessionRef = useRef(null);

  const [state, setState] = useState({
    isAvatarSpeaking: false,
    isAvatarInitialized: false,
    avatarError: null,
    sessionStatus: 'disconnected',
  });

  const initializeAvatarSession = useCallback(async () => {
    try {
      setState(prev => ({...prev, avatarError: null, sessionStatus: 'connecting'}));

      const response = await axios.get('/create-session-token');
      const token = response.data.token;
      console.log(response.data);

      streamingClient.current = new StreamingAvatar({
        token,
        baseURL: config.baseURL,
      });

      streamingClient.current.on(StreamingEvents.STREAM_READY, (event) => {
        if (avatarVideoRef.current) {
          avatarVideoRef.current.srcObject = event.detail;
          avatarVideoRef.current.play().catch(error => {
            setState(prev => ({...prev, avatarError: error.message}));
          });
        }
      });

      streamingClient.current.on(StreamingEvents.AVATAR_START_TALKING, () => {
        setState(prev => ({...prev, isAvatarSpeaking: true}));
        callbacksRef.current.onStartTalking?.();
      });

      streamingClient.current.on(StreamingEvents.AVATAR_STOP_TALKING, () => {
        setState(prev => ({...prev, isAvatarSpeaking: false}));
        callbacksRef.current.onStopTalking?.();
      });

      streamingClient.current.on(StreamingEvents.STREAM_DISCONNECTED, () => {
        setState(prev => ({...prev, sessionStatus: 'disconnected'}));
      });

      const sessionData = await streamingClient.current.createStartAvatar({
        avatarName: config.avatar.avatarName,
        voice: config.avatar.voice,
        quality: AvatarQuality.High,
        disableIdleTimeout: config.avatar.disableIdleTimeout
      });

      sessionRef.current = sessionData;

      setState(prev => ({
        ...prev,
        isAvatarInitialized: true,
        sessionStatus: 'connected'
      }));

      return {
        isInit: true
      }

    } catch (error) {
      setState(prev => ({
        ...prev,
        avatarError: error.response?.data?.message || error.message,
        sessionStatus: 'error'
      }));

      return {
        isInit: false,
        error: error
      }
    }
  }, [config]);

  const avatarSpeak = useCallback(async (message) => {
    try {
      if (!streamingClient.current) {
        throw new Error('Session not initialized');
      }

      await streamingClient.current.speak({
        text: message,
        task_type: 'repeat',
      });

    } catch (error) {
      setState(prev => ({...prev, avatarError: error.message}));
    }
  }, []);

  const terminateAvatarSession = useCallback(async () => {
    if (streamingClient.current) {
      try {
        await streamingClient.current.room.disconnect();

        const sessionID = sessionRef.current.session_id;
        await axios.post('/stop-session', {sessionID: sessionID})
      } catch(err) {
        console.error(err);
      }
      streamingClient.current = null;
      setState(prev => ({
        ...prev,
        isAvatarInitialized: false,
        sessionStatus: 'disconnected'
      }));
    }
  }, []);

  const registerCallbacks = useCallback(({ onStart, onStop }) => {
    callbacksRef.current.onStartTalking = onStart;
    callbacksRef.current.onStopTalking = onStop;
  }, []);

  useEffect(() => {
    return () => {
      if (streamingClient.current) {
        console.log(streamingClient.current);
        streamingClient.current.room.disconnect();
      }
    };
  }, []);

  return {
    ...state,
    avatarVideoRef,
    initializeAvatarSession,
    avatarSpeak,
    terminateAvatarSession,
    registerCallbacks,
  };
}