import './App.css';
import React, { useState, useRef } from 'react';
import { ScreenProvider, useScreenContext } from './Contexts/ScreenContext';
import { DataProvider, useDataContext } from './Contexts/DataContext';
import { AudioDeviceProvider } from './Contexts/AudioDeviceContext';
import { VoiceInputProvider } from './Contexts/VoiceInputContext';
import { Start, Survey, End, ErrorComponent } from './Components/index';

const App = () => {
  return (
    <ScreenProvider>
      <Screens />
    </ScreenProvider>
  );
};

const Screens = () => {
  const { currentScreen } = useScreenContext();

  const [ws, setWs] = useState(null);
  const surveyRef = useRef(null);
  const [avatarActive, setAvatarActive] = useState(false);
  const [audioTalkActive, setAudioTalkActive] = useState(false);

  return (
    <div className='app'>
      <DataProvider>
        <AudioDeviceProvider>
          <VoiceInputProvider>
            {currentScreen === "start" && <Start setAvatarActive={setAvatarActive} setAudioTalkActive={setAudioTalkActive} />}
            {currentScreen === "survey" && <Survey isAvatarActive={avatarActive} isAudioTalkActive={audioTalkActive} />}
            {currentScreen === "end" && <End/>}
            {currentScreen === "error" && <ErrorComponent />}
          </VoiceInputProvider>
        </AudioDeviceProvider>
      </DataProvider>
    </div>
  )
}

export default App;
