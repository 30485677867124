import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDataContext } from '../../Contexts/DataContext';
import axios from '../../axios';
import DisableApp from './DisableApp';

const Admin = () => {
    const [isValid, setIsValid] = useState(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [tokenBalance, setTokenBalance] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    const { surveyData, setSurveyData, setUserData, systemPromptData, setSystemPromptData, promptData, setPromptData, setQuestionsData, setExternalInfo, setSavedAIPhrases, offlineAppPhrases } = useDataContext();
    const [tempQuestions , setTempQuestions] = useState([]);

    useEffect(() => {
        if(!token) {
            navigate('/');
            return;
        }

        axios.post('/adminVerify', {token: token}).then((response) => {
            console.log(response);
            if(response.data.status === 'ok') {
                setIsValid(true);
            } else {
                setIsValid(false);
                setTimeout(() => navigate('/'), 2000);
            }
        })
    }, [token, navigate])

    useEffect(() => {
        if(isValid) {
        axios.get('/getStartData', {
            params: {
                surveyId: 1
            },

        }).then(async(response) => {

            if(response.data.status === "ok") {
                setTokenBalance(response.data.tokenBalance);
                
                setSurveyData(response.data.surveyData);
                setUserData({userId: 1});

                setPromptData(response.data.prompts);
                setSystemPromptData(response.data.systemPrompt[0]);
                setExternalInfo(response.data.externalInfo);

                console.log("Prompts loaded", response.data.prompts);
                console.log("System loaded", response.data.systemPrompt);
                console.log("External info loaded", response.data.externalInfo);

                setTempQuestions(response.data.questions);
                setSavedAIPhrases(response.data.phrases);
                console.log("Questions loaded", response.data.questions);

                setContentLoaded(true);

            }
        })
        }
    }, [isValid]);

    const handleUpdateSystemPrompt = async() => {
        await axios.post('/updateSystemPrompt', {prompt: systemPromptData}).then(async(response) => {
            console.log(response.data);
        })
    }

    const handleTextAreaChange = (id, value) => {
        if(value.length > 0) {
            setPromptData((prev) =>
                prev.map((item) =>
                  item.id === id ? { ...item, prompt: value } : item
                )
            );
        }
    }

    const handleUpdatePrompt = async(id) => {
        const prompt = promptData.find((prompt) => prompt.id === id);
        await axios.post('/updatePrompt', {
            promptId: id,
            newPrompt: prompt.prompt
        }).then(async(response) => {
            console.log(response.data);
        })
    }

    const handleQuestionTextAreaChange = (id, value) => {
        setTempQuestions((prev) =>
            prev.map((item) =>
              item.id === id ? { ...item, hint: value ? value : " " } : item
            )
        );
    }

    const handleUpdateQuestion = async(id) => {
        const question = tempQuestions.find((question) => question.id === id);
        await axios.post('/updateQuestion', {
            questionId: id,
            newHint: question.hint
        }).then(async(response) => {
            console.log(response.data);
        })
    }

    return (
        <div>
            {isValid && contentLoaded ? (
                <div style={{background: '#232D7D', color: 'white'}}>
                    <div className='admin__disable-app'>
                        <DisableApp />
                    </div>
                    <div className='admin__prompts' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 64}}>
                        <h3 style={{marginBottom: 64}}>Current Heygen token balance: {parseFloat(tokenBalance).toFixed(2)}</h3>
                    <div className='start__update-prompts-container'>
                                <p>INFO FOR PROMPTS</p>
                                <span>Najedź na gwiazdkę aby dowiedzieć się więcej o prompcie.</span>
                                <div className='start__update-prompts-boxes'>
                                    <div className='start__update-prompts'>
                                        <div className='start__system-prompt'>
                                            <div>
                                                <label>System Prompt</label>
                                                <textarea value={systemPromptData.prompt} onChange={(e) => setSystemPromptData({id: 1, prompt: e.target.value})}></textarea>
                                            </div>
                                            <button onClick={() => handleUpdateSystemPrompt()}>Update</button>
                                        </div>
                                        {promptData.map((prompt) => (
                                            <div key={prompt.id} className='start__prompt'>
                                                <div>
                                                    <div>
                                                        <label>{prompt.variable}</label>
                                                        <div className='start__system-prompt__hint'> * </div>
                                                        <div className='start__absolute-hint'>{prompt.hint}</div>
                                                    </div>
                                                    <textarea value={prompt.prompt} onChange={(e) => handleTextAreaChange(prompt.id, e.target.value)}></textarea>
                                                </div>
                                                <button onClick={() => handleUpdatePrompt(prompt.id)}>Update</button>
                                            </div>
                                        ))}
                                        <p style={{marginTop: '50px'}}>Question hints</p>
                                        {tempQuestions.map((question) => (
                                            <div key={question.id} className='start__prompt'>
                                                <div>
                                                    <div>
                                                        <label>{question.question.slice(0, 64)} {question.question.length > 64 ? "..." : ""}</label>
                                                    </div>
                                                    <textarea placeholder='Cant be empty' value={question.hint} onChange={(e) => handleQuestionTextAreaChange(question.id, e.target.value)}></textarea>
                                                </div>
                                                <button onClick={() => handleUpdateQuestion(question.id)}>Update</button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            ) : (
                <div>
                    Wait...
                </div>
            )}
        </div>
    )
}

export default Admin;