import { EventEmitter } from 'events';
import { VOICE_STATES, VAD_CONFIG, VOICE_PROCESSING } from '../Config/voice.config.js';
import { MicVAD } from '@ricky0123/vad-web';
import { audioDeviceManager } from './AudioDeviceManager.js';
import axios from './../axios.js';

class VoiceInputService extends EventEmitter {
    constructor() {
        super();
        this.vad = null;
        this.currentState = VOICE_STATES.IDLE;
        this.audioContext = null;
        this.isInitialized = false;
        this.processingTimeout = null;
        this.openAIService = null;
        this.misfireCount = 0;
        this.lastMisfireTime = 0;
        this.isAudioActive = false;
    }

    async initialize() {
        if (this.isInitialized) return true;

        try {
            // Initialize audio device manager first
            await audioDeviceManager.initialize();

            // Get the selected device
            const selectedDevice = audioDeviceManager.getCurrentDevice();
            if (!selectedDevice) {
                throw new Error('No audio device selected');
            }

            const myvad = await MicVAD.new({
                onSpeechStart: () => this.handleSpeechStart(),
                onSpeechEnd: (audio) => this.handleSpeechEnd(audio),
                onVADMisfire: () => this.handleVADMisfire(),
                onError: (error) => this.handleError(error),
                onMicrophoneStart: () => this.handleMicrophoneStart(),
                onMicrophoneStop: () => this.handleMicrophoneStop(),
                deviceId: selectedDevice.deviceId,
                ...VAD_CONFIG
            });
            
            this.vad = myvad;
            this.isInitialized = true;
            this.setState(VOICE_STATES.IDLE);

            // Listen for device changes
            audioDeviceManager.on('deviceSelected', async (device) => {
                await this.handleDeviceChange(device);
            });

            return true;
        } catch (error) {
            console.log('Failed to initialize VoiceInputService', {
                error,
                selectedDevice: audioDeviceManager.getCurrentDevice()?.deviceId,
                vadConfig: VAD_CONFIG
            });
            this.setState(VOICE_STATES.ERROR);
            throw error;
        }
    }

    /**
     * Handle device change events
     * @private
     * @param {MediaDeviceInfo} device - The newly selected device
     */
    async handleDeviceChange(device) {
        try {
            // Stop current VAD instance
            if (this.vad) {
                if (typeof this.vad.pause === 'function') {
                    this.vad.pause();
                }
                if (typeof this.vad.destroy === 'function') {
                    this.vad.destroy();
                }
                this.vad = null;
            }

            // Create new VAD instance with new device
            const myvad = await MicVAD.new({
                onSpeechStart: () => this.handleSpeechStart(),
                onSpeechEnd: (audio) => this.handleSpeechEnd(audio),
                onVADMisfire: () => this.handleVADMisfire(),
                onError: (error) => this.handleError(error),
                onMicrophoneStart: () => this.handleMicrophoneStart(),
                onMicrophoneStop: () => this.handleMicrophoneStop(),
                deviceId: device.deviceId,
                ...VAD_CONFIG
            });

            this.vad = myvad;
            this.setState(VOICE_STATES.IDLE);
        } catch (error) {
            console.log('Failed to handle device change', {
                error,
                deviceId: device?.deviceId,
                deviceLabel: device?.label,
                currentState: this.currentState
            });
            this.setState(VOICE_STATES.ERROR);
            throw error;
        }
    }

    handleMicrophoneStart() {
        this.isAudioActive = true;
        this.emit('audioChannelChange', true);
    }

    handleMicrophoneStop() {
        this.isAudioActive = false;
        this.emit('audioChannelChange', false);
    }

    async start() {
        try {
            if (!this.isInitialized) {
                await this.initialize();
            }

            if (this.currentState === VOICE_STATES.LISTENING) return;

            // Reset misfire tracking on start
            this.misfireCount = 0;
            this.lastMisfireTime = 0;

            if (this.vad && typeof this.vad.start === 'function') {
                this.vad.start();
                this.setState(VOICE_STATES.LISTENING);
            } else {
                console.log('VAD not properly initialized');
            }
        } catch (error) {
            console.log('Failed to start voice input', {
                error,
                currentState: this.currentState,
                isInitialized: this.isInitialized,
                hasVAD: !!this.vad
            });
            this.setState(VOICE_STATES.ERROR);
            throw error;
        }
    }

    async stop() {
        try {
            if (!this.vad || this.currentState === VOICE_STATES.IDLE) return;

            if (this.vad && typeof this.vad.pause === 'function') {
                this.vad.pause();
                this.setState(VOICE_STATES.IDLE);
            } else {
                throw new Error('VAD not properly initialized');
            }
        } catch (error) {
            console.log('Failed to stop voice input', {
                error,
                currentState: this.currentState,
                hasVAD: !!this.vad
            });
            this.setState(VOICE_STATES.ERROR);
            throw error;
        }
    }

    handleSpeechStart() {
        if (this.currentState === VOICE_STATES.LISTENING) {
            this.setState(VOICE_STATES.SPEAKING);
            this.emit('speechStart');
        }
    }

    async handleSpeechEnd(audio) {
        if (this.currentState === VOICE_STATES.SPEAKING) {
            // Convert audio to format needed for processing
            const audioData = {
                samples: audio,
                sampleRate: VOICE_PROCESSING.sampleRate
            };

            this.setState(VOICE_STATES.PROCESSING);
            this.emit('speechEnd', audioData);
            
            // Return to IDLE state after emitting the event
            this.setState(VOICE_STATES.IDLE);
        }
    }

    async transcribeAudio(audioData) {
        try {
            // Verify sample rate is 16kHz as required by Whisper
            if (audioData.sampleRate !== 16000) {
                throw new Error('Audio must be 16kHz for Whisper API');
            }

            // Convert Float32Array to Int16Array (16-bit PCM)
            const audioInt16 = new Int16Array(audioData.samples.length);
            for (let i = 0; i < audioData.samples.length; i++) {
                // Normalize and scale to 16-bit range
                const s = Math.max(-1, Math.min(1, audioData.samples[i]));
                audioInt16[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
            }
            
            // Create WAV file
            const wavBuffer = this.createWAVFile(audioInt16, audioData.sampleRate);
            const audioBlob = new Blob([wavBuffer], { type: 'audio/wav' });
            
            // Create form data as required by Whisper API
            const formData = new FormData();
            formData.append('file', audioBlob, 'speech.wav');

            // Send to backend endpoint that will forward to OpenAI
            const response = await axios.post('/transcribe', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              timeout: 15000 // 15 sekund timeout
            });
    
            if (response.data.error) throw new Error(response.data.error);

            return response.data.text;

        } catch (error) {
            // Only log validation errors or non-HTTP errors
            if (error.message.includes('16kHz') || !error.message.includes('HTTP error')) {
                this.logger.error('Failed to transcribe audio', error);
            }
            throw error;
        }
    }

    createWAVFile(samples, sampleRate) {
        const buffer = new ArrayBuffer(44 + samples.length * 2);
        const view = new DataView(buffer);

        // Write WAV header
        this.writeString(view, 0, 'RIFF');                     // RIFF identifier
        view.setUint32(4, 36 + samples.length * 2, true);     // File size
        this.writeString(view, 8, 'WAVE');                     // WAVE identifier
        this.writeString(view, 12, 'fmt ');                    // fmt chunk
        view.setUint32(16, 16, true);                         // Chunk size
        view.setUint16(20, 1, true);                          // Audio format (1 = PCM)
        view.setUint16(22, 1, true);                          // Number of channels (1 = mono)
        view.setUint32(24, sampleRate, true);                 // Sample rate
        view.setUint32(28, sampleRate * 2, true);             // Byte rate
        view.setUint16(32, 2, true);                          // Block align
        view.setUint16(34, 16, true);                         // Bits per sample
        this.writeString(view, 36, 'data');                   // data chunk
        view.setUint32(40, samples.length * 2, true);         // Data size

        // Write audio data
        for (let i = 0; i < samples.length; i++) {
            view.setInt16(44 + i * 2, samples[i], true);
        }

        return buffer;
    }

    writeString(view, offset, string) {
        for (let i = 0; i < string.length; i++) {
            view.setUint8(offset + i, string.charCodeAt(i));
        }
    }

    handleVADMisfire() {
        const now = Date.now();
        const timeSinceLastMisfire = now - this.lastMisfireTime;

        // Reset misfire count if more than 5 seconds have passed
        if (timeSinceLastMisfire > 5000) {
            this.misfireCount = 0;
        }

        this.misfireCount++;
        this.lastMisfireTime = now;

        console.log('VAD misfire detected', {
            misfireCount: this.misfireCount,
            timeSinceLastMisfire
        });

        if(this.misfireCount < 3) {
            this.setState(VOICE_STATES.LISTENING);
        }
        
        // Only emit misfire event if we've had multiple misfires in quick succession
        if (this.misfireCount >= 3 && timeSinceLastMisfire < 5000) {
            this.emit('vadMisfire');
            // Return to LISTENING state if we were SPEAKING
            if (this.currentState === VOICE_STATES.SPEAKING) {
                this.setState(VOICE_STATES.LISTENING);
            }
        }
    }

    handleError(error) {
        console.log('VAD error occurred', {
            error,
            currentState: this.currentState,
            isAudioActive: this.isAudioActive,
            misfireCount: this.misfireCount
        });
        this.setState(VOICE_STATES.ERROR);
        this.emit('error', error);
    }

    setState(newState) {
        const previousState = this.currentState;
        this.currentState = newState;

        console.log('Voice input state changed', {
            from: previousState,
            to: newState,
            isAudioActive: this.isAudioActive
        });

        this.emit('stateChange', newState, {
            previousState,
            isAudioActive: this.isAudioActive
        });
    }

    async cleanup() {
        console.log('Cleaning up voice input service');
        
        try {
            // Stop VAD
            if (this.vad) {
                if (typeof this.vad.pause === 'function') {
                    this.vad.pause();
                }
                if (typeof this.vad.destroy === 'function') {
                    this.vad.destroy();
                }
                this.vad = null;
            }

            // Clear any timeouts
            if (this.processingTimeout) {
                clearTimeout(this.processingTimeout);
                this.processingTimeout = null;
            }

            // Reset state and flags without triggering state changes
            this.isInitialized = false;
            this.isAudioActive = false;
        } catch (error) {
            console.log('Failed to cleanup voice input service', {
                error,
                currentState: this.currentState,
                hasVAD: !!this.vad,
                hasTimeout: !!this.processingTimeout
            });
            // Don't throw - cleanup should always complete
        }
    }

    getCurrentState() {
        return this.currentState;
    }
}

// Export as singleton
export const voiceInputService = new VoiceInputService() 