import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import NoPageComponent from './Components/NoPage/NoPage';
import AdminLogin from './Components/Admin/Login';
import Admin from './Components/Admin/Admin';
import { DataProvider, useDataContext } from './Contexts/DataContext';
import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />} />
        <Route path='adminlogin' element={<AdminLogin />} />
        <Route path='admin' element={<DataProvider><Admin /></DataProvider>} />
        <Route path='*' element={<NoPageComponent />} />
      </Routes>
    </BrowserRouter>
    
  </React.StrictMode>
);