import { EventEmitter } from 'events';

/**
 * Manages audio device selection and state
 * Responsible for:
 * - Detecting available audio input devices
 * - Managing device selection UI
 * - Handling device changes
 * - Persisting device selection
 */
class AudioDeviceManager extends EventEmitter {
    constructor() {
        super();
        this.currentDevice = null;  // Only keep one device state
        this.availableDevices = [];
        this.isInitialized = false;
        this.isUpdatingDevices = false;
        this.lastUpdateTime = 0;
        this.updateDebounceTime = 1000;

        // Bind methods
        this.handleDeviceChange = this.handleDeviceChange.bind(this);
        this.handleDeviceSelect = this.handleDeviceSelect.bind(this);

        if (typeof window !== 'undefined') {
            window.addEventListener('DOMContentLoaded', () => {
                this.updateAvailableDevices().catch(() => {
                    // Error already logged in updateAvailableDevices
                });
            });
        }
    }

    /**
     * Verify if device has recording capabilities
     * @private
     */
    async verifyDeviceCapabilities(device) {
        try {
            await navigator.mediaDevices.getUserMedia({
                audio: { deviceId: { exact: device.deviceId } }
            });
            return true;
        } catch {
            return false;
        }
    }

    /**
     * Restore device selection from localStorage
     * @private
     */
    async _restoreDeviceSelection() {
        const savedDeviceId = localStorage.getItem('selectedAudioDevice');
        if (savedDeviceId) {
            console.log(this.availableDevices);
            const savedDevice = this.availableDevices.find(d => d.deviceId === savedDeviceId);
            if (savedDevice) {
                //this.deviceSelect.value = savedDeviceId;
                await this.handleDeviceSelect({ target: { value: savedDeviceId } });
                return true;
            }
            //localStorage.removeItem('selectedAudioDevice');
        }
        return false;
    }

    /**
     * Select first available device
     * @private
     */
    async _selectFirstAvailableDevice() {
        if (this.availableDevices.length > 0) {
            const firstDevice = this.availableDevices[0];
            //this.deviceSelect.value = firstDevice.deviceId;
            await this.handleDeviceSelect({ target: { value: firstDevice.deviceId } });
            return true;
        }
        return false;
    }

    /**
     * Initialize the audio device manager
     * @returns {Promise<boolean>} Success status
     */
    async initialize() {
        try {
            if (this.isInitialized && this.currentDevice) {
                return true;
            }

            // Setup device selection listener
            //this.deviceSelect.removeEventListener('change', this.handleDeviceSelect);
            //this.deviceSelect.addEventListener('change', this.handleDeviceSelect);

            // Update available devices
            if (!await this.updateAvailableDevices()) {
                throw new Error('Failed to update available devices');
            }

            // if(!this.currentDevice) {
            //     if(!await this._restoreDeviceSelection()) {
            //         await this._selectDefaultSystemDevice();
            //     }
            // }

            // Setup device change listener
            navigator.mediaDevices.removeEventListener('devicechange', this.handleDeviceChange);
            navigator.mediaDevices.addEventListener('devicechange', this.handleDeviceChange);
            
            this.isInitialized = true;
            return true;
        } catch (error) {
            console.log('Failed to initialize AudioDeviceManager', error);
            return false;
        }
    }

    /**
     * Get and verify recording devices
     * @private
     */
    async _getRecordingDevices() {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const seenDeviceIds = new Set();
        return devices.filter(device => {
            if (device.kind !== 'audioinput' || 
                !device.deviceId || 
                !device.label ||
                device.deviceId === 'default' ||
                device.deviceId === 'communications' ||
                seenDeviceIds.has(device.deviceId)) {
                return false;
            }
            seenDeviceIds.add(device.deviceId);
            return true;
        });
    }

    /**
     * Update UI with available devices
     * @private
     */
    _updateDeviceUI() {
        if (!this.deviceSelect) return;

        // Clear existing options except the default one
        while (this.deviceSelect.options.length > 1) {
            this.deviceSelect.remove(1);
        }

        // Add devices to select
        this.availableDevices.forEach(device => {
            const option = document.createElement('option');
            option.value = device.deviceId;
            option.text = device.label;
            this.deviceSelect.add(option);
        });

        if (this.availableDevices.length === 0) {
            if (this.deviceStatus) {
                this.deviceStatus.innerHTML = '<span style="color:red">NO RECORDING DEVICES</span>';
            }
            this.deviceSelect.classList.add('hidden');
        } else {
            this.deviceSelect.classList.remove('hidden');
            this.deviceSelect.classList.remove('error');
            if (!this.currentDevice && this.availableDevices.length > 0) {
                const firstDevice = this.availableDevices[0];
                this.deviceSelect.value = firstDevice.deviceId;
            }
        }
    }

    /**
     * Update the list of available devices
     * @private
     */
    async updateAvailableDevices() {
        if (Date.now() - this.lastUpdateTime < this.updateDebounceTime || this.isUpdatingDevices) {
            return true;
        }

        try {
            this.isUpdatingDevices = true;
            this.lastUpdateTime = Date.now();
            this.availableDevices = [];
            this.showPreloader();

            try {
                //await navigator.mediaDevices.getUserMedia({ audio: true });
            } catch (error) {
                console.log('Failed to get microphone permission', error);
                if (this.deviceStatus) {
                    this.deviceStatus.innerHTML = '<span style="color:red">NO PERMISSION</span>';
                }
                if (this.deviceSelect) {
                    this.deviceSelect.classList.add('hidden');
                }
                return false;
            }

            const inputDevices = await this._getRecordingDevices();
            
            for (const device of inputDevices) {
                if (await this.verifyDeviceCapabilities(device)) {
                    this.availableDevices.push(device);
                }
            }

            if (!this.currentDevice) {
                if (!await this._restoreDeviceSelection()) {
                    await this._selectDefaultSystemDevice();
                }
            }
            this._updateDeviceUI();
            this.emit('devicesUpdated', this.availableDevices);
            return this.availableDevices.length > 0;
        } catch (error) {
            console.log('Failed to update available devices', error);
            return false;
        } finally {
            this.isUpdatingDevices = false;
            this.hidePreloader();
        }
    }

    /**
     * Handle device selection change
     * @private
     */
    async handleDeviceSelect(event) {
        const deviceId = event.target.value;
        console.log('handleDeviceSelect called with deviceId:', deviceId);
        
        if (!deviceId) {
            await this.clearDeviceSelection();
            return;
        }

        const device = this.availableDevices.find(d => d.deviceId === deviceId);
        if (!device) {
            console.log('Device not found in available devices:', deviceId);
            await this.clearDeviceSelection();
            return;
        }

        try {
            await this.validateAndSetDevice(device);
        } catch (error) {
            console.log('Failed to validate device:', error);
            await this.clearDeviceSelection();
        }
    }

    /**
     * Clear device selection
     * @private
     */
    async clearDeviceSelection() {
        this.currentDevice = null;
        if (this.deviceStatus) {
            this.deviceStatus.innerHTML = '<span style="color:red">Select device</span>';
        }
        this.deviceSelect?.classList.add('error');
        //localStorage.removeItem('selectedAudioDevice');
        this.emit('devicePreselected', null);
    }

    /**
     * Validate and set device
     * @private
     */
    async validateAndSetDevice(device) {
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: { 
                deviceId: { exact: device.deviceId },
                echoCancellation: true,
                noiseSuppression: true,
                autoGainControl: true
            }
        });
        
        stream.getTracks().forEach(track => track.stop());
        
        this.currentDevice = device;
        this.deviceSelect?.classList.remove('error');
        
        if (this.deviceStatus) {
            this.deviceStatus.textContent = device.label;
        }
        
        localStorage.setItem('selectedAudioDevice', device.deviceId);
        this.emit('devicePreselected', device);
        this.emit('deviceSelected', device);
    }

    /**
    * Selects the system default audio input device.
    * @private
    */
    async _selectDefaultSystemDevice() {
        await this.handleDeviceSelect({ target: { value: this.availableDevices[0].deviceId } });
        return true;
    }

    /**
     * Show device selector
     */
    showDeviceSelect() {
        if (this.deviceSelect) {
            this.deviceSelect.classList.remove('hidden');
            if (this.deviceText) {
                this.deviceText.classList.add('hidden');
            }
            if (this.deviceStatus) {
                this.deviceStatus.classList.add('hidden');
            }
        }
    }

    /**
     * Hide device selector and show device name
     */
    hideDeviceSelect() {
        if (this.deviceSelect && this.currentDevice) {
            this.deviceSelect.classList.add('hidden');
            if (this.deviceText) {
                this.deviceText.classList.remove('hidden');
            }
            if (this.deviceStatus) {
                this.deviceStatus.classList.remove('hidden');
                this.deviceStatus.textContent = this.currentDevice.label;
            }
        }
    }

    /**
     * Reset device selection
     */
    reset() {
        this.currentDevice = null;
        this.isInitialized = false;
        
        // Clear UI elements
        if (this.deviceSelect) {
            this.deviceSelect.value = '';
            this.deviceSelect.classList.remove('error');
        }
        if (this.deviceStatus) {
            this.deviceStatus.textContent = 'Select device';
        }
        
        // Clear stored device
        localStorage.removeItem('selectedAudioDevice');
        this.emit('devicePreselected', null);
    }

    /**
     * Handle device change events
     * @private
     */
    async handleDeviceChange() {
        console.log('Audio devices changed');
        await this.updateAvailableDevices();
        
        // Check if current device is still available
        if (this.currentDevice) {
            const isStillAvailable = this.availableDevices.some(
                device => device.deviceId === this.currentDevice.deviceId
            );
            
            if (!isStillAvailable) {
                console.log('Current audio device no longer available');
                if (this.availableDevices.length > 0) {
                    this.currentDevice = this.availableDevices[0];
                    if (this.deviceStatus) {
                        this.deviceStatus.textContent = this.currentDevice.label;
                    }
                    this.emit('deviceSelected', this.currentDevice);
                } else {
                    this.currentDevice = null;
                    if (this.deviceStatus) {
                        this.deviceStatus.innerHTML = '<span style="color:red">NO INPUT DEVICE</span>';
                    }
                    this.emit('noDeviceAvailable');
                }
            }
        }
    }

    /**
     * Get the current audio input device
     * @returns {MediaDeviceInfo|null} Current device
     */
    getCurrentDevice() {
        return this.currentDevice;
    }

    /**
     * Get all available audio input devices
     * @returns {MediaDeviceInfo[]} Available devices
     */
    getAvailableDevices() {
        return [...this.availableDevices];
    }

    /**
     * Select a specific audio input device
     * @param {string} deviceId Device ID to select
     * @returns {Promise<boolean>} Success status
     */
    async selectDevice(deviceId) {
        const device = this.availableDevices.find(d => d.deviceId === deviceId);
        if (!device) {
            throw new Error('Device not found');
        }

        try {
            // Test if we can actually use this device
            await navigator.mediaDevices.getUserMedia({
                audio: { deviceId: { exact: deviceId } }
            });

            this.currentDevice = device;
            this.hideDeviceSelect();
            this.emit('deviceSelected', device);

            localStorage.setItem('selectedAudioDevice', device.deviceId);
            console.log(localStorage.getItem('selectedAudioDevice'));
            
            console.log('Audio device selected', {
                deviceId: device.deviceId,
                label: device.label
            });
            
            return true;
        } catch (error) {
            console.log('Failed to select audio device:', error);
            throw error;
        }
    }

    /**
     * Clean up resources
     */
    cleanup() {
        // Remove event listeners
        navigator.mediaDevices.removeEventListener('devicechange', this.handleDeviceChange);
        if (this.deviceSelect) {
            this.deviceSelect.removeEventListener('change', this.handleDeviceSelect);
        }
        
        // Reset state and UI
        this.reset();
        
        // Notify about cleanup
        this.emit('cleanup');
        this.emit('deviceSelected', null);  // Notify that no device is selected
    }

    /**
     * Show preloader and hide select
     */
    showPreloader() {
        if (this.devicePreloader && this.deviceSelect) {
            this.devicePreloader.classList.remove('hidden');
            this.deviceSelect.classList.add('loading');
        }
    }

    /**
     * Hide preloader and show select
     */
    hidePreloader() {
        if (this.devicePreloader && this.deviceSelect) {
            this.devicePreloader.classList.add('hidden');
            this.deviceSelect.classList.remove('loading');
        }
    }
}

// Export as singleton
export const audioDeviceManager = new AudioDeviceManager(); 